<template>
  <div>
    <div
      v-if="
        deliveryFee &&
          deliveryServiceId == 2 &&
          deliveryFee.deliveryFeeRows &&
          deliveryFee.deliveryFeeRows.length > 1
      "
      class="delivery-fee grey lighten-2 rounded pa-3"
    >
      <div class="text-h4 primary--text font-weight-bold">
        {{ $t("deliveryFee.listTitle") }}
      </div>
      <div class="delivery-fee-list mt-2" v-if="showList">
        <div
          class="default--text"
          v-for="(deliveryFeeRow, index) in deliveryFee.deliveryFeeRows"
          :key="deliveryFeeRow.deliveryFeeRowId"
          :class="index == indexDeliveryFeeRange ? 'font-weight-bold' : ''"
        >
          <span v-if="index < deliveryFee.deliveryFeeRows.length - 1">
            {{
              $t("deliveryFee.deliveryRangeDetail", {
                fee: $n(deliveryFeeRow.fee, "currency"),
                range: $n(deliveryFeeRow.range, "currency")
              })
            }}
          </span>
          <span v-else>
            {{
              $t("deliveryFee.deliveryRangeDetailOver", {
                fee: $n(deliveryFeeRow.fee, "currency"),
                range: $n(deliveryFeeRow.range, "currency")
              })
            }}
          </span>
        </div>
        <div class="caution mt-3" v-html="$t('deliveryFee.caution')"></div>
      </div>

      <div
        v-else-if="deliveryFee.deliveryFeeRows.length > 0 && discountText"
        :class="optionClass"
      >
        <span class="primary--text font-weight-bold">{{ discountText }}</span>
        <v-progress-linear :value="ammountToReachDiscount"></v-progress-linear>
        <div
          v-for="deliveryFeeRow in this.deliveryFee.deliveryFeeRow"
          :key="deliveryFeeRow.deliveryFeeRowId"
          class="d-flex align-items"
        >
          <v-progress-linear
            :value="actualPercentaceToMaxGoal"
          ></v-progress-linear>
          <div class="pallino-colorato"></div>
        </div>
      </div>
    </div>
    <div
      v-if="remaining > 0"
      class="subtitle-2 minimum-purchase delivery-fee_minimum-purchase"
    >
      {{ $t("checkout.minimumPurchase", [$n(remaining, "currency")]) }}
    </div>

    <div
      v-if="cart.deliveryFee > 0 && remainingAmountForNextFee > 0"
      class="subtitle-2 delivery-fee_minimum-purchase"
    >
      {{
        $t("checkout.remainingAmountForNextFee", [
          $n(remainingAmountForNextFee, "currency"),
          $n(minimumAmountForNextFee, "currency")
        ])
      }}
    </div>
  </div>
</template>
<style lang="scss">
.delivery-fee {
  .discount {
    margin: 3px 0;
  }
  .navbar-delivery-fee {
    font-size: 11px;
  }
}
.delivery-fee_minimum-purchase {
  color: var(--v-error-base);
}
</style>
<script>
import DeliveryService from "~/service/deliveryService";
import CartService from "~/service/cartService";

import { mapState, mapGetters } from "vuex";

export default {
  name: "DeliveryFee",
  data() {
    return {
      minimumPurchase: null,
      deliveryFee: null,
      minimunAmmountToDiscount: 15,
      ammountToReachDiscount: 0,
      minimumAmountForNextFee: 0,
      remainingAmountForNextFee: 0,
      initTotalPrice: 0
    };
  },
  props: {
    showList: { type: Boolean, default: true },
    optionClass: { type: String, default: "discount grey lighten-2" }
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      deliveryServiceId: "cart/getDeliveryServiceId"
    }),
    remaining() {
      return this.minimumPurchase - this.cart.totalPrice;
    },

    discountText() {
      if (this.deliveryFee) {
        for (let i = 0; i < this.deliveryFee.deliveryFeeRows.length; i++) {
          if (
            this.deliveryFee.deliveryFeeRows[i].range > this.cart.totalPrice
          ) {
            let amountToDiscount =
              this.deliveryFee.deliveryFeeRows[i].range - this.cart.totalPrice;
            if (amountToDiscount < this.minimunAmmountToDiscount) {
              // se meno di minimunAmmountToDiscount € non mostriamo
              this.$emit("changeShowingDeliveryFee", true);
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.ammountToReachDiscount =
                ((this.minimunAmmountToDiscount - amountToDiscount) * 100) /
                this.minimunAmmountToDiscount;
              return this.$t("deliveryFee.amountToDiscount", {
                amount: this.$n(amountToDiscount, "currency")
              });
            }
          }
        }
      }
      this.$emit("changeShowingDeliveryFee", false);
      return null;
    },
    // actualPercentaceToMaxGoal() {
    //   let perc = this.maxGrossTotalGoal
    //     ? (this.cart.totalPrice * 100) / this.maxGrossTotalGoal
    //     : 0;
    //   return perc;
    // },
    // maxGrossTotalGoal() {
    //   let goal = 0;
    //   if (this.deliveryFee?.deliveryFeeRows.length > 0) {
    //     goal =
    //       this.deliveryFee.deliveryFeeRows.length > 1
    //         ? this.deliveryFee.deliveryFeeRows[
    //             this.deliveryFee.deliveryFeeRows.length - 2
    //           ].range
    //         : this.deliveryFee.deliveryFeeRows[0].range;
    //   }
    //   return goal;
    // },
    indexDeliveryFeeRange() {
      if (this.deliveryFee) {
        for (let i = 0; i < this.deliveryFee.deliveryFeeRows.length; i++) {
          if (
            this.cart.totalPrice > this.deliveryFee.deliveryFeeRows[i].range &&
            !(
              this.deliveryFee.deliveryFeeRows[i + 1] &&
              this.cart.totalPrice >
                this.deliveryFee.deliveryFeeRows[i + 1].range
            )
          ) {
            console.log(
              this.deliveryFee.deliveryFeeRows[i].range,
              this.cart.totalPrice
            );
            return i + 1;
          }
        }
      }
      return 0;
    }
  },
  methods: {
    async getDeliveryFee() {
      this.deliveryFee =
        this.deliveryServiceId == 2
          ? await DeliveryService.getDeliveryFee()
          : (this.deliveryFee = null);
    },
    async reload() {
      let purchaseRestriction = await CartService.getPurchaseRestriction();
      console.log("dew", purchaseRestriction);
      this.minimumPurchase = purchaseRestriction.minimumPurchase;

      let _this = this;
      await this.getDeliveryFee();
      let totalPrice = this.cart.totalPrice;
      if (
        this.deliveryFee != null &&
        this.deliveryFee.deliveryFeeRows != null
      ) {
        for (
          let index = 0;
          index < this.deliveryFee.deliveryFeeRows.length;
          index++
        ) {
          const elementRow = this.deliveryFee.deliveryFeeRows[index];

          if (elementRow.range > this.cart.totalPrice + 0.01) {
            if (index + 1 < this.deliveryFee.deliveryFeeRows.length) {
              _this.remainingAmountForNextFee =
                0.01 + elementRow.range - totalPrice;
              _this.minimumAmountForNextFee = this.deliveryFee.deliveryFeeRows[
                index + 1
              ].fee;
            } else {
              _this.remainingAmountForNextFee = 0;
              _this.minimumAmountForNextFee = 0;
            }
            break;
          }
        }
      }
    }
  },
  created() {
    this.getDeliveryFee();
  },
  async mounted() {
    this.reload();
    this.initTotalPrice = this.cart.totalPrice;
  },
  watch: {
    cart(val, oldVal) {
      if (
        val.totalPrice != oldVal.totalPrice &&
        val.totalPrice != this.initTotalPrice
      ) {
        this.reload();
      }
    }
  }
};
</script>
