<template>
  <v-card
    outlined
    type="grey lighten2"
    flat
    class="d-flex align-center align-sm-start align-lg-center justify-space-between cart-info-card w-100 h-100 px-3 py-3 px-sm-3 py-sm-3 lighten2"
  >
    <div class="d-flex flex-row align-start">
      <v-icon v-if="icon" x-large color="primary" class="icon-info-card">
        {{ icon }}
      </v-icon>
      <v-img
        :src="imgSrc"
        v-else-if="imgSrc"
        contain
        class="img-info-card"
        alt="Immagine servizio"
        height="40"
        width="40"
      ></v-img>
      <div class="ml-3 ml-sm-0 ml-lg-3">
        <div class="text text-h5">{{ text }}</div>
        <div v-if="subtitle" class="subtitle text-body-2">{{ subtitle }}</div>
        <div class="value">{{ infoValue }}</div>
      </div>
    </div>
    <v-icon
      @click="$emit('edit')"
      class="edit"
      color="default"
      right
      v-if="editable"
    >
      $edit
    </v-icon>
  </v-card>
</template>
<style lang="scss">
.cart-info-card {
  @mixin hide-img-icon {
    .icon-info-card,
    .img-info-card {
      display: none;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    @include hide-img-icon;
  }
  @media #{map-get($display-breakpoints, 'md-only')} {
    @include hide-img-icon;
  }
}
</style>
<script>
export default {
  name: "CartInfoCard",
  props: {
    icon: { type: String, required: false },
    imgSrc: { type: String, required: false },
    text: { type: String, required: true },
    subtitle: { type: String, required: false },
    infoValue: { type: String, required: true },
    editable: { type: Boolean, default: true }
  }
};
</script>
