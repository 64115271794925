<template>
  <v-card class="card-complete-profile">
    <v-card-title>
      {{ $t("checkout.completeProfileTitle") }}
    </v-card-title>

    <v-card-subtitle>
      {{ $t("checkout.completeProfileSubTitle") }}
    </v-card-subtitle>

    <v-card-actions>
      <v-btn outlined text class="complete-profile-btn" to="/profile/profile">
        {{ $t("checkout.completeProfileTitle") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.card-complete-profile {
  margin-bottom: 15px !important;
}
</style>
<script>
export default {
  name: "CheckoutCompleteProfile"
};
</script>

<style lang="scss">
.card-complete-profile {
  background: #ff8484 0% 0% no-repeat padding-box !important;
  color: white !important;
  .v-card__title {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .v-card__subtitle {
    color: white !important;
    font-size: 16px !important;
  }
  .complete-profile-btn {
    background: white !important;
    color: #ff8484 !important;
    border: 2px solid #ff8484 !important;
    width: 100% !important;
  }
}
</style>
